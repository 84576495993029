import React from 'react';
import { withRouter } from 'react-router-dom';
import _startCase from 'lodash/startCase';
import { defineMessages, injectIntl } from 'react-intl';
import { KyruusFormattedMessage } from '@kyruus/intl';

import { LocationFacet } from './location-facet';
import { BasicFacet } from './basic-facet';
import { TypeaheadFacet } from './typeahead-facet/typeahead-facet';
import { FacetFieldSet, StyledLegend } from '../../styles';

const messages = defineMessages({
  clear: {
    id: 'facet.clearall',
    description: 'Text to clear all applied filters on the facet',
    defaultMessage: 'Clear'
  },
  clearallAriaLabel: {
    id: 'facet.clearallAriaLabel',
    description: 'Text to clear all applied filters on the facet',
    defaultMessage: 'Clear All {facetName} filters'
  },
  searchFilterFormDescription: {
    id: 'facet.form.description',
    description: 'Accessibility description for the search filter form.',
    defaultMessage:
      'Changing an input in this form will immediately trigger a new search and subsequent change of context.'
  }
});

export const FacetContainer = withRouter(
  injectIntl(
    ({
      searchSummary,
      config,
      customerConfig,
      distanceSort,
      facetName,
      getUpdatedSearch,
      history,
      log
    }) => {
      const addFilter = function (filter) {
        var params = [{ action: 'append', key: 'filter', value: filter }];
        history.push(getUpdatedSearch(params));
      };
      const removeFilter = function (filter) {
        history.push(
          getUpdatedSearch([
            { action: 'delete_key_value', key: 'filter', value: filter }
          ])
        );
      };
      const searchableLocation =
        config.allow_location_change !== false || !searchSummary.location;

      const facetLabelMessageDescriptor = {
        id: `field.name.${config.field}`,
        defaultMessage: _startCase(config.field),
        description: 'The name displayed above the facet'
      };

      const facetKey = config.field;
      const customerConfigFacetConfig = (customerConfig?.facets_v9 || []).find(
        (facetConfig) => facetConfig.field === facetKey
      );
      const facetIsComposite =
        !!customerConfigFacetConfig && !!customerConfigFacetConfig?.composite;

      let facet = {};
      switch (config.type) {
        case 'location':
          facet = (
            <LocationFacet
              config={config}
              customerConfig={customerConfig}
              distanceSort={distanceSort}
              facetName={facetName}
              getUpdatedSearch={getUpdatedSearch}
              labelName={facetLabelMessageDescriptor}
              log={log}
              searchableLocation={searchableLocation}
              searchSummary={searchSummary}
            />
          );
          break;
        case 'typeahead':
          facet = (
            <TypeaheadFacet
              config={config}
              facetName={facetName}
              facetLabelMessageDescriptor={facetLabelMessageDescriptor}
              hideCount={facetIsComposite}
              addFilter={addFilter}
              removeFilter={removeFilter}
              log={log}
              labelName={facetLabelMessageDescriptor}
              searchableLocation={searchableLocation}
              getUpdatedSearch={getUpdatedSearch}
            />
          );
          break;
        default:
          facet = (
            <BasicFacet
              config={config}
              facetName={facetName}
              hideCount={facetIsComposite}
              addFilter={addFilter}
              removeFilter={removeFilter}
              log={log}
              labelName={facetLabelMessageDescriptor}
              searchableLocation={searchableLocation}
              getUpdatedSearch={getUpdatedSearch}
            />
          );
      }

      return (
        <FacetFieldSet className="filter-group">
          <StyledLegend>
            <KyruusFormattedMessage {...facetLabelMessageDescriptor} />
          </StyledLegend>
          {facet}
        </FacetFieldSet>
      );
    }
  )
);

FacetContainer.displayName = 'FacetContainer';

export const FacetList = ({
  searchSummary,
  config,
  facets,
  getUpdatedSearch,
  distanceSort,
  log,
  showLocationFacet
}) => {
  var facet_list = facets.map((facetConfig) => {
    if (facetConfig.field === 'location' && !showLocationFacet) return null;
    if (
      [
        'locations.primary_marketable_location_id',
        'locations.associated_marketable_location_ids',
        'provider.id'
      ].includes(facetConfig.field)
    ) {
      return null;
    }

    return (facetConfig.field === 'location' &&
      facetConfig.distance_options.length) ||
      (facetConfig.terms && facetConfig.terms.length) ? (
      <FacetContainer
        searchSummary={searchSummary}
        key={facetConfig.field}
        facetName={facetConfig.field}
        customerConfig={config}
        config={facetConfig}
        distanceSort={distanceSort}
        getUpdatedSearch={getUpdatedSearch}
        log={log}
      />
    ) : null;
  });

  return (
    <form
      id="searchFilters"
      name="searchFilters"
      onSubmit={(e) => e.preventDefault()}
      aria-describedby="searchFilterFormDescription"
    >
      <KyruusFormattedMessage {...messages.searchFilterFormDescription}>
        {(msg) => (
          <span id="searchFilterFormDescription" className="sr-only">
            {msg}
          </span>
        )}
      </KyruusFormattedMessage>
      {facet_list}
    </form>
  );
};

FacetList.displayName = 'FacetList';
