import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { KyruusFormattedMessage, kyruusFormatMessage } from '@kyruus/intl';
import _kebabCase from 'lodash/kebabCase';

import { ShowMoreResultsButton } from '../../../styles';

const messages = defineMessages({
  showmore: {
    id: 'facet.showmore',
    description: 'Title for button to show more results',
    defaultMessage: 'Show More'
  },
  showless: {
    id: 'facet.showless',
    description: 'Title for button to show fewer results',
    defaultMessage: 'Show Less'
  }
});

export const ShowMoreButton = injectIntl(
  ({
    allTerms,
    facetName,
    hideTerms,
    intl,
    labelName,
    maxVisible,
    onClick,
    terms
  }) => {
    if (allTerms.length <= maxVisible || terms.length === 0) return null;

    let showMoreLessText;
    if (hideTerms) {
      showMoreLessText = <KyruusFormattedMessage {...messages.showmore} />;
    } else {
      showMoreLessText = <KyruusFormattedMessage {...messages.showless} />;
    }

    return (
      <ShowMoreResultsButton
        id={_kebabCase(facetName) + '-facet-show-hide'}
        data-testid={_kebabCase(facetName) + '-facet-show-hide'}
        onClick={onClick}
        aria-label={`${kyruusFormatMessage(
          intl,
          hideTerms ? messages.showmore : messages.showless
        )} ${kyruusFormatMessage(intl, labelName)}`}
        mode="flat"
      >
        {showMoreLessText}
      </ShowMoreResultsButton>
    );
  }
);

ShowMoreResultsButton.displayName = 'ShowMoreButton';
